import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout.en";
import SEO from "../components/seo";
import ContactFormEN from "../components/contactForm.en";
import { WOW } from "wowjs";
import Wave from "react-wavify";
import Swiper from "react-id-swiper";
import SlickSlider from "react-slick";
import moment from "moment";
import MainVideo from "../../static/video/output.mp4";

const swiperParams = {
    pagination: {
        el: ".swiper-pagination",
        type: "custom",
        renderCustom: function(e, t, i) {
            var a = (100 / i) * t;
            // eslint-disable-next-line no-useless-concat
            return (a = a.toFixed(6)), '<div className="progressbar"><div className="filled" data-width="' + a + '" style="width: ' + a + "%" + '"></div></div>'; // eslint-disable-line no-sequences
        },
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
        },
        1025: {
            slidesPerView: 2,
        },
    },
    spaceBetween: 20,
    autoplay: 0,
    loop: false,
    slidesPerView: 1,
    loopedSlides: 9,
    autoplayDisableOnInteraction: true,
};

class IndexPage extends Component {
    componentDidMount() {
        const wow = new WOW({
            boxClass: "wow",
            animateClass: "animate",
            offset: 100,
            mobile: false,
            live: true,
        });

        wow.init();
    }

    shortify = text => {
        const latinMap = {
            á: "a",
            à: "a",
            ã: "a",
            â: "a",
            ç: "c",
            é: "e",
            ê: "e",
            í: "i",
            ó: "o",
            õ: "o",
            ô: "o",
            ú: "u",
        };
        return text
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\-a-z0-9]/g, x => latinMap[x] || x)
            .replace(/[^\-a-z0-9]/g, "");
    };

    render() {
        const { data } = this.props;

        return (
            <Layout>
                <SEO title="Jamstack Developers in Lisbon" description="Cottonhat is a web team in Lisbon using the latest technologies to develop custom solutions" lang="en" />
                <div className="bk-about-area section-ptb-100 mt-5" id="section-about">
                    <div className="container">
                        <div className="row about--creative align-items-center">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="thumb text-left text-lg-left wow move-up">
                                    <Img fluid={data.heroImage.childImageSharp.fluid} style={{ maxHeight: 671, maxWidth: 532 }} alt="Cottonhat web studio in Portugal, Lisbon" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                                <div className="content pl--80 pl_md--5 pl_sm--5">
                                    <div className="title--creative wow move-up">
                                        <h2>
                                            Faster, secure
                                            <br />
                                            by nature <br /> <span className="theme-creative">JAMstack</span> developers
                                        </h2>
                                    </div>
                                    <p className="wow move-up">Transform your business with modern web technologies and best practices</p>
                                    <Link to="/en/contact-us/" className="about-btn wow move-up">
                                        <span>Work with us</span>
                                        <i class="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brook-what-do-area ptb--160 ptb-md--80 ptb-sm--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-2">
                                <div className="brook-section-title wow move-up">
                                    <h5 className="heading heading-h5 theme-color">What we do</h5>
                                    <div className="bkseparator--30"></div>
                                    <h3 className="heading heading-h3 line-height-1-42">
                                        Web solutions for
                                        <br />
                                        outstanding businesses
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="row mt--20 mt_sm--5 mt_md--5">
                            <div className="col-lg-2">
                                <div className="what-do mt--40 mt_md--20 mt_sm--5">
                                    <div className="basic-separator line-3"></div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="what-do mt--40">
                                    <div className="content">
                                        <h5 className="heading heading-h5 theme-color wow move-up">Customer Centric</h5>
                                        <div className="bkseparator--20"></div>
                                        <p className="bk_pra wow move-up">We put our customers and their experience first and foremost in everything we do by focusing on developing strong and positive relationships.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 offset-lg-1">
                                <div className="what-do mt--40">
                                    <div className="content">
                                        <h5 className="heading heading-h5 theme-color wow move-up">Custom-made solutions</h5>
                                        <div className="bkseparator--20"></div>
                                        <p className="bk_pra wow move-up">
                                            A big part of our mission is to create custom-made solutions for YOUR business, tailored to your needs and goals. By using undistry standards, modern approaches and open software we strive to achieve the best compromise between design and functionality.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brook-video-area bg_color--1 pb--10 pb_md--80 pb_sm--60 bg-as-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-12">
                                <div className="video-with-thumb text-center move-up wow">
                                    <div className="thumb">
                                        <video autoPlay muted loop style={{ width: "100%" }}>
                                            <source src={MainVideo} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-12 mt_md--40 mt_sm--40">
                                <div className="video-content move-up wow">
                                    <h3 className="heading heading-h3 line-height-1-42">
                                        Effective solution <br /> for every businesses
                                    </h3>
                                    <div className="bkseparator--25"></div>
                                    <p className="bk_pra">Our expertise is translated across multiple industries: pharmaceutical, retail, loyalty, fashion, cosmetics, CRM / ERP and Advertisement. Get to know more about our projects.</p>
                                    <div className="bkseparator--40"></div>
                                    <Link className="moredetails-btn yellow-color-2" to="/en/services/">
                                        <span>More details</span> <i class="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bk-testimonial-area section-ptb-xl bg_color--1 poss_relative pt_sm--0">
                    <div className="wavify-wrapper">
                        <Wave
                            className="wavify-item"
                            fill="rgba(245,245,245,0.5)"
                            style={{ top: 0 }}
                            paused={false}
                            options={{
                                height: 140,
                                amplitude: 80,
                                speed: 0.3,
                                points: 4,
                            }}
                        />
                        <Wave
                            className="wavify-item"
                            fill="#f5f5f5"
                            style={{ top: 0 }}
                            paused={false}
                            options={{
                                height: 140,
                                amplitude: 80,
                                speed: 0.2,
                                points: 3,
                            }}
                        />
                    </div>

                    <div className="container section-pt-xl">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-2">
                                <div className="brook-section-title wow move-up">
                                    <div className="bkseparator--30"></div>
                                    <h3 className="heading heading-h3 line-height-1-42">feedback from our clients</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-wrapper testimonial--horizontal testimonial--horizontal--active pagination-style-01 mt--80 wow move-up">
                        <Swiper className="testimonial-container swiper-wrapper" {...swiperParams}>
                            <div className="testimonial testimonial_style--1 swiper-slide">
                                <div className="content">
                                    <p>“A empresa Cottonhat é responsável pela criação do nosso site, temos um site moderno, actual, intuitivo e com muito bons conteúdos fotográficos. Com uma equipa de profissionais dedicados que prestam um serviço exemplar à Ruika, somos parceiros de sucesso.”</p>
                                    <div className="testimonial-info">
                                        <div className="clint-info">
                                            <h6>Rui Nascimento</h6>
                                            <span>Ruika Shoes</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-quote">
                                        <span className="fa fa-quote-right"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial testimonial_style--1 swiper-slide">
                                <div className="content">
                                    <p>“Sempre atentos às nossas necessidades, criaram o produto que nós pretendíamos.”</p>
                                    <div className="testimonial-info">
                                        <div className="clint-info">
                                            <h6>Ricardo Bastos</h6>
                                            <span>Rede 360</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-quote">
                                        <span className="fa fa-quote-right"></span>
                                    </div>
                                </div>
                            </div>
                        </Swiper>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>

                <div className="bk-portfolio-area">
                    <div className="black-bg-wrapper bg_color--3 section-pt-xl pb--240">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-2">
                                    <div className="brook-section-title wow move-up">
                                        <div className="bkseparator--30"></div>
                                        <h3 className="heading heading-h3 line-height-1-42" style={{ color: "#fff" }}>
                                            get to know how we create value for our customers
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="portfolio-wrapper pl--150 pr--150 pl_lg--50 pr_lg--50 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">
                        <div className="row row--0 space_dec--110">
                            <div className="col-lg-6">
                                <div className="portfolio portfolio_style--1 home-hover">
                                    <div className="thumb">
                                        <img src={`${data.cs.Cottonhat_casestudyList[0].photo.url}?w=810&h=810&fit=crop`} alt={data.cs.Cottonhat_casestudyList[0].title.en_EN} />
                                    </div>
                                    <Link to={`/en/case-study/${data.cs.Cottonhat_casestudyList[0].title.en_EN ? this.shortify(data.cs.Cottonhat_casestudyList[0].title.en_EN) : data.cs.Cottonhat_casestudyList[0].uri}`}>
                                        <div className="port-overlay-info" style={{ backgroundColor: data.cs.Cottonhat_casestudyList[0].overlayColor }}>
                                            <div className="hover-action">
                                                <h3 className="post-overlay-title">
                                                    <Link to={`/en/case-study/${data.cs.Cottonhat_casestudyList[0].title.en_EN ? this.shortify(data.cs.Cottonhat_casestudyList[0].title.en_EN) : data.cs.Cottonhat_casestudyList[0].uri}`}>
                                                        {data.cs.Cottonhat_casestudyList[0].title.en_EN ? data.cs.Cottonhat_casestudyList[0].title.en_EN : data.cs.Cottonhat_casestudyList[0].title}
                                                    </Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row row--0">
                                    <div className="col-lg-12 wow move-up">
                                        <div className="portfolio portfolio_style--1 home-hover">
                                            <div className="thumb">
                                                <img src={`${data.cs.Cottonhat_casestudyList[1].photo.url}?w=810&h=405&fit=crop`} alt={data.cs.Cottonhat_casestudyList[1].title.en_EN} />
                                            </div>
                                            <Link to={`/en/case-study/${data.cs.Cottonhat_casestudyList[1].title.en_EN ? this.shortify(data.cs.Cottonhat_casestudyList[1].title.en_EN) : data.cs.Cottonhat_casestudyList[1].uri}`}>
                                                <div className="port-overlay-info" style={{ backgroundColor: data.cs.Cottonhat_casestudyList[1].overlayColor }}>
                                                    <div className="hover-action">
                                                        <h3 className="post-overlay-title">
                                                            <Link to={`/en/case-study/${data.cs.Cottonhat_casestudyList[1].title.en_EN ? this.shortify(data.cs.Cottonhat_casestudyList[1].title.en_EN) : data.cs.Cottonhat_casestudyList[1].uri}`}>
                                                                {data.cs.Cottonhat_casestudyList[1].title.en_EN ? data.cs.Cottonhat_casestudyList[1].title.en_EN : data.cs.Cottonhat_casestudyList[1].title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 wow move-up">
                                        <div className="portfolio portfolio_style--1 home-hover">
                                            <div className="thumb">
                                                <img src={`${data.cs.Cottonhat_casestudyList[2].photo.url}?w=480&h=480&fit=crop`} alt={data.cs.Cottonhat_casestudyList[2].title.en_EN} />
                                            </div>
                                            <Link to={`/en/case-study/${data.cs.Cottonhat_casestudyList[2].title.en_EN ? this.shortify(data.cs.Cottonhat_casestudyList[2].title.en_EN) : data.cs.Cottonhat_casestudyList[2].uri}`}>
                                                <div className="port-overlay-info" style={{ backgroundColor: data.cs.Cottonhat_casestudyList[2].overlayColor }}>
                                                    <div className="hover-action">
                                                        <h3 className="post-overlay-title">
                                                            <Link to={`/en/case-study/${data.cs.Cottonhat_casestudyList[2].title.en_EN ? this.shortify(data.cs.Cottonhat_casestudyList[2].title.en_EN) : data.cs.Cottonhat_casestudyList[2].uri}`}>
                                                                {data.cs.Cottonhat_casestudyList[2].title.en_EN ? data.cs.Cottonhat_casestudyList[2].title.en_EN : data.cs.Cottonhat_casestudyList[2].title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 wow move-up">
                                        <div className="portfolio portfolio_style--1 home-hover">
                                            <div className="thumb">
                                                <img src={`${data.cs.Cottonhat_casestudyList[3].photo.url}?w=480&h=480&fit=crop`} alt={data.cs.Cottonhat_casestudyList[3].title.en_EN} />
                                            </div>
                                            <Link to={`/en/case-study/${data.cs.Cottonhat_casestudyList[3].title.en_EN ? this.shortify(data.cs.Cottonhat_casestudyList[3].title.en_EN) : data.cs.Cottonhat_casestudyList[3].uri}`}>
                                                <div className="port-overlay-info" style={{ backgroundColor: data.cs.Cottonhat_casestudyList[3].overlayColor }}>
                                                    <div className="hover-action">
                                                        <h3 className="post-overlay-title">
                                                            <Link to={`/en/case-study/${data.cs.Cottonhat_casestudyList[3].title.en_EN ? this.shortify(data.cs.Cottonhat_casestudyList[3].title.en_EN) : data.cs.Cottonhat_casestudyList[3].uri}`}>
                                                                {data.cs.Cottonhat_casestudyList[3].title.en_EN ? data.cs.Cottonhat_casestudyList[3].title.en_EN : data.cs.Cottonhat_casestudyList[3].title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bk-brand-area bg_color--1 ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="brand-wrapper">
                                    <div className="brand__list brand-default brand-style--1">
                                        <SlickSlider classNameName="brook-element-carousel" autoplay={true} spaceBetween={15} slidesToShow={5} slidesToScroll={1} arrows={false} infinite={true}>
                                            <div className="brand">
                                                <Link href="https://tswarriorplayer.com/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/tswarriorplayer_164x164.png" alt="E-commerce project made by cottonhat web studio lisbon portugal for tswarriorplayer" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="https://play.google.com/store/apps/details?id=pt.homa.ecommerce" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/homa_164x164.png" alt="Mobile app project made by cottonhat web studio lisbon portugal for hôma" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/maisfarmacia_164x164.png" alt="Custom CRM project made by cottonhat web studio lisbon portugal for maisfarmacia" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/bial_164x164.png" alt="Project made by cottonhat web studio lisbon portugal for bial" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/deborla_164x164.png" alt="Mobile app project made by cottonhat web studio lisbon portugal for deborla" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/loreal_164x164.png" alt="Project made by cottonhat web studio lisbon portugal for loreal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/audi_v2_164x164.png" alt="Landing pages made by cottonhat web studio lisbon portugal for audi" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/bioderma_164x164.png" alt="Custom CRM project made by cottonhat web studio lisbon portugal for bioderma" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="https://hangus.pt/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/hangus_164x164.png" alt="E-commerce project made by cottonhat web studio lisbon portugal for hangus" />
                                                </Link>
                                            </div>
                                        </SlickSlider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bk-blog-area">
                    <div
                        className="black-bg-wrapper bg_image--4 section-pt-xl pb--320"
                        style={{
                            backgroundImage: "url(/images/blog_bg.jpg)",
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-2">
                                    <div className="brook-section-title wow move-up">
                                        <div className="bkseparator--30"></div>
                                        <h3 className="heading heading-h3 line-height-1-42">from our Blog</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="blog-wrapper pl--230 pr--230 pr_lp--100 pl_lp--100 pl_lg--100 pr_lg--100 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30 section-pb-xl">
                        <div className="row row--0 space_dec--250">
                            {data.blog.Cottonhat_blogList.map(item => {
                                return (
                                    <div className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 wow move-up">
                                        <div className="blog blog-style--1">
                                            <div className="thumb">
                                                <Link to={`/en/blog/${item.title.en_EN ? this.shortify(item.title.en_EN) : item.uri}`}>
                                                    <img src={`${item.photo.url}?w=480&h=480&fit=crop`} alt="Blog Images" />
                                                </Link>
                                            </div>
                                            <div className="blog-content blog-position--bottom home-blog-block">
                                                <div className="content">
                                                    <ul className="meta">{moment(item.creat_default.createdAt).format("LL")}</ul>
                                                    <h3 className="the-title">
                                                        <Link to={`/en/blog/${item.title.en_EN ? this.shortify(item.title.en_EN) : item.uri}`}>{item.title.en_EN ? item.title.en_EN : item.title}</Link>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="bk-contact-area section-pb-xl bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-12">
                                <div className="address-inner">
                                    <div className="address wow move-up">
                                        <h3 className="heading">visit us</h3>
                                        <p>
                                            <Link href="https://goo.gl/maps/vm3gtyZzC6jQnVNR9" target="_blank" rel="noreferrer">
                                                Rua Margarida de Abreu N 13, Esc 3
                                            </Link>
                                            <br />
                                            Lisbon, Portugal
                                        </p>
                                    </div>
                                    <div className="address mt--60 mb--60 wow move-up">
                                        <h3 className="heading">keep in touch</h3>
                                        <p>
                                            <a href="mailto:info@cottonhat.net">info@cottonhat.net</a>
                                        </p>
                                        <p>
                                            <a href="tel:00351927344725">(+351) 308 814 283</a>
                                        </p>
                                    </div>
                                    <ul className="social-icon icon-size-medium text-dark text-left tooltip-layout move-up wow">
                                        <li className="facebook">
                                            <a href="https://www.facebook.com/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Facebook">
                                                <i className="fab fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="instagram">
                                            <a href="https://www.instagram.com/cottonhat_studio/" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Instagram">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li className="behance">
                                            <a href="https://www.behance.net/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Behance">
                                                <i className="fab fa-behance"></i>
                                            </a>
                                        </li>
                                        <li className="linkedin">
                                            <a href="https://www.linkedin.com/company/cottonhat/" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Linkedin">
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                        {/* <li className="twitter">
                                            <a href="https://twitter.com/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Twitter">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-8 offset-xl-1 col-lg-9 col-12 mt_md--40 mt_sm--40">
                                <ContactFormEN />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default IndexPage;

export const query1 = graphql`
    query {
        blog: wbapi {
            Cottonhat_blogList(project: "592d4cf5b549b452a450d829", page: 1, limit: 3) {
                project_id
                title
                _id
                uri
                normalized_name
                creat_default {
                    createdAt
                }
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
        cs: wbapi {
            Cottonhat_casestudyList(project: "592d4cf5b549b452a450d829", page: 1, limit: 4) {
                project_id
                title {
                    en_EN
                    pt_PT
                }
                _id
                normalized_name
                overlayColor
                uri
                creat_default {
                    createdAt
                }
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
        heroImage: file(relativePath: { eq: "banner-1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 532, quality: 100) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    originalImg
                }
            }
        }
    }
`;
